import React from 'react'

import { Layout } from '../components'

import binoculars from '../assets/404.svg'

const NotFoundPage = () => (
  <Layout>
    <div className='PageContainer Container404'>
      <div className='ContentContainer404'>
        <h1 className='Heading404'><span>404 Page not found.</span></h1>
        <p className='SubHeading404'>We searched in front and behind the firewall but couldn’t find the page you were looking for.</p>
        <p><a href='/'>Go back home</a></p>
      </div>
      <div className='ImgContainer404'>
        <img
          src={ binoculars }
          alt='SigSci binoculars'
          className='Img404'
        />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
